
import {Component, Vue} from 'vue-property-decorator';

@Component({})
export default class UserGuide extends Vue {
	public data() {
      return {
        visible_accordion_1: this.$route.params.visible_accordion_1 != null ?
        this.$route.params.visible_accordion_1 : true,
        visible_accordion_2: this.$route.params.visible_accordion_2 != null ?
        this.$route.params.visible_accordion_2 : true,
      };
    }
}
